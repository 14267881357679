<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import ThemeToggleButton from '@/components/ThemeToggleButton.vue'
import IconDotsVertical from './icons/IconDotsVertical.vue'
import IconCart from '@/components/icons/IconCart.vue'
import AuthButton from '@/components/user_auth_components/AuthButton.vue'
import DropDownMenu from '@/components/DropDownMenu.vue'
import { useUserStore } from '@/stores/lugand-api/userStore'

const isSidebarOpen = ref(false)
const userStore = useUserStore()
const router = useRouter()

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value
}

const closeSidebar = (event: MouseEvent) => {
  const sidebar = document.querySelector('.sidebar')
  const navbar = document.querySelector('.navbar')
  if (
    isSidebarOpen.value &&
    sidebar &&
    !sidebar.contains(event.target as Node) &&
    navbar &&
    !navbar.contains(event.target as Node)
  ) {
    isSidebarOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', closeSidebar)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closeSidebar)
})

const navigateToHome = () => {
  router.push({ name: 'home' })
}

const navigateToAllProducts = () => {
  router.push({ name: 'product' })
}

const navigateToAllServices = () => {
  router.push({ name: 'service' })
}

const navigateToAdvancedSearch = () => {
  router.push({ name: 'search-results' })
}

const navigateToAbout = () => {
  router.push({ name: 'about-us' })
}

const navigateToAccount = () => {
  router.push({ name: 'user-account' })
}

const navigateToCarts = () => {
  router.push({ name: 'cart-view' })
}

const navigateToFavorites = () => {
  router.push({ name: 'favorites' })
}
</script>

<template>
  <div>
    <nav class="navbar">
      <div class="navbar-content">
        <div class="flex-small-menus">
          <div class="global-menu">
            <button class="home-btn" @click="navigateToHome">
              <h1>Lugand</h1>
            </button>
            <button class="theme-toggle" @click="toggleSidebar">
              <IconDotsVertical />
            </button>
          </div>

          <div class="global-search search-lx"></div>

          <div class="user-settings">
            <button id="icon-cart" class="theme-toggle" @click="navigateToCarts">
              <IconCart />
            </button>

            <div style="display: flex">
              <DropDownMenu buttonText="">
                <!--botão-->
                <template #icon>
                  <template v-if="userStore.user">
                    <!-- Exibe o avatar_image se existir, senão usa a photoURL -->
                    <img
                      :src="
                        userStore.user.avatar_image
                          ? userStore.user.avatar_image
                          : userStore.user.photoURL
                      "
                      alt="User"
                      class="user-photo"
                    />
                    <div id="navbar-user-name-text">
                      <!--utiliza o nick_name se existir, se não utiliza o display_name-->
                      <span>{{
                        userStore.user.nick_name ? userStore.user.nick_name : userStore.user.name
                      }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <i class="fa-solid fa-user fa-xl"></i>
                  </template>
                </template>
                <!--conteudo do dropdown-->
                <p @click="navigateToAccount">My Account</p>
                <p @click="navigateToCarts">My Carts</p>
                <p @click="navigateToFavorites">My Favorites</p>
                <ThemeToggleButton />
                <AuthButton />
              </DropDownMenu>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div :class="{ sidebar: true, 'sidebar-open': isSidebarOpen }">
      <ul class="menu">
        <li @click="navigateToHome">
          <button class="theme-toggle">
            <i class="icon-home" />
          </button>
          <span>Início</span>
        </li>
        <li class="sidebar-title">PRODUTOS</li>
        <li @click="navigateToAllProducts">
          <button class="theme-toggle">
            <i class="icon-home" />
          </button>
          <span>Todos os Produtos</span>
        </li>
        <li @click="navigateToAdvancedSearch">
          <button class="theme-toggle">
            <i class="icon-home" />
          </button>
          <span>Busca Avançada</span>
        </li>
        <li class="sidebar-title">SERVIÇOS</li>
        <li @click="navigateToAllServices">
          <button class="theme-toggle">
            <i class="icon-home" />
          </button>
          <span>Todos os Serviços</span>
        </li>
        <li @click="navigateToHome">
          <button class="theme-toggle">
            <i class="icon-home" />
          </button>
          <span>Smart Central</span>
        </li>
        <li @click="navigateToHome">
          <button class="theme-toggle">
            <i class="icon-home" />
          </button>
          <span>Consultoria</span>
        </li>
        <li class="sidebar-title">INSTITUCIONAL</li>
        <li @click="navigateToAbout">
          <button class="theme-toggle">
            <i class="icon-about" />
          </button>
          <span>Sobre nós</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.search-sm {
  display: none;
  justify-content: center;
}

.flex-small-menus {
  display: flex;
  width: 100%;
}

#navbar-user-name-text {
  margin: auto;
}

#icon-cart {
  border-radius: 0px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.fa-user {
  margin-top: 15px;
  padding: 0%;
  width: 66px;
}

.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

i.icon-home {
  margin-top: -6px;
}

i.icon-about {
  margin-top: -6px;
  margin-left: -2px;
}

.home-btn {
  padding: 5px;
  border-radius: 0%;
  border: 1px solid var(--color-border);
  background-color: var(--color-border);
  color: aliceblue;
}

.global-menu {
  display: flex;
  justify-content: flex-start;
}

.global-menu-btn {
  border: 1px solid var(--color-border);
  background-color: var(--color-border);
  color: var(--color-background);
  border-radius: 5px;
  height: 30px;
}

.global-menu-btn i {
  margin: auto;
}

.global-menu .theme-toggle {
  border-radius: 0px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.navbar-content .user-settings {
  display: flex;
  justify-content: flex-end;
}

.sidebar-title {
  color: aliceblue;
  background-color: var(--color-border);
}

button {
  display: flex;
}

.navbar {
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-shadow-border);
  color: var(--color-text);
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.navbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.global-search {
  margin: auto;
}

.sidebar {
  position: fixed;
  left: -250px;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: var(--color-background);
  color: var(--color-text);
  transition: left 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  margin-top: 37px;
}

.sidebar:not(.sidebar-open) {
  width: 60px;
  overflow: hidden;
}

.sidebar:not(.sidebar-open) .menu li {
  justify-content: center;
}

.sidebar:not(.sidebar-open) .menu li span {
  display: none;
}

.sidebar-open {
  left: 0;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.menu li {
  display: flex;
  align-items: center;
  padding: 0.5em;
  cursor: pointer;
}

.menu li i {
  margin-right: 1em;
}

.menu li:hover {
  background-color: var(--color-border);
  color: var(--color-background);
}

.menu span {
  padding-left: 10px;
}

@media (max-width: 768px) {
  .flex-small-menus .search-lx {
    display: none;
  }

  .sidebar {
    margin-top: 37px;
  }

  .navbar-content {
    flex-direction: column;
  }

  .flex-small-menus {
    display: flex;
    justify-content: space-between;
    padding: 0%;
    margin: 0%;
  }

  .user-settings {
    justify-content: flex-end;
  }

  .global-menu {
    justify-content: flex-end;
  }

  .global-search {
    display: block;
    width: 100%;
    margin: auto;
  }

  .global-menu .theme-toggle {
    border-radius: 0px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
  }
  #icon-cart {
    border-radius: 0px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
  }
}
</style>
