import BannerLocalStructure from '@/assets/images/banners/banner-local-structure.jpg'
import BannerCloudStructure from '@/assets/images/banners/banner-cloud-structure.jpg'
import BannerHybridStructure from '@/assets/images/banners/banner-hybrid-structure.jpg'
import BannerCustomStructure from '@/assets/images/banners/banner-custom-structure.jpg'

export interface Banner {
  image: string
  title: string
  description: string
}
const banners: Banner[] = [
  {
    image: BannerLocalStructure,
    title: 'Local',
    description: 'Controle sua Smart Central em sua rede local sem custos adicionais'
  },
  {
    image: BannerCloudStructure,
    title: 'Cloud',
    description: 'Acesse sua Smart Central de forma remota, conecte-se com diversos serviços'
  },
  {
    image: BannerHybridStructure,
    title: 'Hybrid',
    description:
      'Estrutura flexivel que permite um ambiente hibrido utilizando a rede local e a nuvem'
  },
  {
    image: BannerCustomStructure,
    title: 'Custom',
    description:
      'Voltado para especialistas ou intusiastas em tecnologia a estrutura permite maior controle sobre o funcionamento de sua Smart Central'
  }
  // {
  //   image: 'https://via.placeholder.com/800x400',
  //   title: 'Banner',
  //   description: 'Example banner structure'
  // }
]
export const getAllBanners = (): Promise<Banner[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('getAllBanners: ', banners)
      resolve(banners)
    }, 500) // Simulate a network delay
  })
}

// Mock for component: GenericTable.vue
const tableData = [
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' }
]
export const getTableData = (): Promise<Record<string, any>[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('getTableData:', tableData)
      resolve(tableData)
    }, 500) // Simulate a network delay
  })
}
