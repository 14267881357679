import axios from 'axios'

export const calculaFrete = async (
  cepDestino: string,
  peso: number,
  altura: number,
  largura: number,
  comprimento: number
) => {
  try {
    const response = await axios.get('http://localhost:8000/api/delivery/calcular-frete', {
      params: {
        cepDestino,
        peso,
        altura,
        largura,
        comprimento
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return response.data
  } catch (error) {
    console.error('Erro ao calcular frete:', error)
    throw new Error('Não foi possível calcular o frete.')
  }
}
