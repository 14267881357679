// src/utils/productUtils.ts

import type { Product } from '@/types/product'

// Função para criar um produto seguro (fallback)
export const createSafeProduct = (productId: number): Product => {
  return {
    id: productId,
    name: 'Produto não encontrado',
    price: {
      id: 0,
      parcelado: {
        id: 0,
        valor: '0',
        numero_parcelas: 0,
        taxa_juros: '0',
        created_at: ''
      },
      product: 0,
      valor_original: '0',
      valor_a_vista: '0',
      created_at: ''
    },
    vendor: {
      id: 0,
      name: 'Desconhecido',
      cnpj: '',
      ativo: false,
      created_at: '',
      last_update: ''
    },
    categories: [],
    subcategories: [],
    discount: {
      id: 0,
      tipo: { id: 0, name: '', created_at: '' },
      valor: '',
      descricao: '',
      data_inicio: '',
      data_termino: '',
      created_at: '',
      ativo: false
    },
    images: [],
    properties: [],
    keywords: [],
    description: '',
    dimensions: {
      id: 0,
      peso: '',
      altura: '',
      largura: '',
      comprimento: ''
    },
    stock_quantity: 0,
    created_at: '',
    updated_at: '',
    ativo: false
  }
}
