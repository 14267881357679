<template>
  <div>
    <h3>Frete</h3>
    <div v-if="frete">
      <p>Valor do frete: R$ {{ frete.valor }}</p>
      <p>Prazo de entrega: {{ frete.prazo }} dias úteis</p>
    </div>
    <div v-else>
      <p>Calculando o frete...</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { calculaFrete } from '@/services/lugand-api-services/calculaFreteService'
import { useCheckoutStore } from '@/stores/lugand-api/checkoutStore'

export default defineComponent({
  name: 'CalculoFrete',
  props: {
    cepDestino: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const frete = ref<{ valor: string; prazo: number } | null>(null)
    const checkoutStore = useCheckoutStore()
    const pesoTotal = checkoutStore.items.reduce((total, item) => {
      return total + (item.product.dimensions.peso ? 1 * item.quantity : 0) // Certifique-se que 'peso' exista
    }, 0)
    const calcularFrete = async () => {
      try {
        const resultado = await calculaFrete(
          props.cepDestino,
          1, // peso
          10, // Defina valores fixos ou variáveis reais para altura
          20, // largura
          20 // comprimento
        )
        frete.value = {
          valor: resultado.valor,
          prazo: resultado.prazoEntrega
        }
      } catch (error) {
        console.error('Erro ao calcular frete:', error)
        console.log(props)
      }
    }

    watch(() => props.cepDestino, calcularFrete, { immediate: true })

    return {
      frete
    }
  }
})
</script>
