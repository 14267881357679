<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import BannerCard from '../components/BannerCard.vue'
import BannerCarousel from '../components/BannerCarousel.vue'
import Header from '../components/Header.vue'
import { type Banner, getAllBanners } from '../services/development/productService'
import LogoAnimation from '../components/animations/LogoAnimation.vue'
import LogoBackgroundAnimation from '../components/animations/LogoBackgroundAnimation.vue'

const router = useRouter()
const banners = ref<Banner[]>([])

const navigateToAllProducts = () => {
  router.push({ name: 'product' })
}

const navigateToAllServices = () => {
  router.push({ name: 'service' })
}
const navigateToService1 = () => {
  router.push({ name: 'service', hash: '#section-1' })
}

onMounted(async () => {
  banners.value = await getAllBanners()
})
</script>

<template>
  <div>
    <div class="">
      <div class="bg-logo-container">
        <LogoBackgroundAnimation />
      </div>
      <div class="logo-container">
        <LogoAnimation />
        <div class="core-btns">
          <button class="core-button" @click="navigateToAllProducts">PRODUTOS</button>
          <button class="core-button" @click="navigateToAllServices">SERVIÇOS</button>
        </div>
      </div>
    </div>
    <Header />
    <div class="container">
      <br />
      <h1 class="title">Nossos Produtos</h1>
      <div class="smart-info">
        <div>
          <h1>Smart Components</h1>
          <div class="smart-info-content">
            <img
              class="info-image"
              src="@/assets/images/home-info/info-smart-things.jpg"
              alt="smart things info"
            />
            <p>
              O uso de componentes inteligentes em aplicações como smart home, smart city e nos
              maiores setores da indústrias vem crescendo com o tempo. Hoje eles são acessíveis para
              todos nós, então separamos alguns dos melhores produtos voltados a IoT (Internet of
              Things) que podem ser utilizados para as mais diversas soluções.
            </p>
            <button class="info-button" @click="navigateToAllProducts">produtos</button>
          </div>
        </div>
      </div>
      <br />
      <h1 class="title">Nossos Serviços</h1>
      <div>
        <div class="card">
          <div class="smart-info">
            <div>
              <h1>Smart Central</h1>
              <div class="smart-info-content">
                <img
                  class="info-image"
                  src="@/assets/images/home-info/info-smart-things.jpg"
                  alt="smart things info"
                />
                <p>
                  Pensando em flexibilidade, segurança e escalabilidade utilizamos tecnologias
                  conhecidas do mercado para criar uma solução moderna para o gerenciamento de
                  estabelecimentos inteligentes sejam eles resisdenciais ou comerciais.
                </p>
                <button class="info-button">serviços</button>
              </div>
            </div>
          </div>
          <br />
          <h2>Tipos de Estrutura</h2>
          <BannerCarousel :slides="banners">
            <template v-slot:default="{ slide }: { slide: Banner }">
              <BannerCard
                :image="slide.image"
                :title="slide.title"
                :description="slide.description"
              />
            </template>
          </BannerCarousel>
          <br />

          <h2>Duvidas frequentes</h2>
          <div class="smart-info info-hover" @click="navigateToAllProducts">
            <h3>Qual a estrutura ideal para minha necessidade?</h3>
            <p>></p>
          </div>
          <div class="smart-info info-hover" @click="navigateToAllProducts">
            <h3>Posso adicionar novos dispositivos inteligentes a minha Smart Central?</h3>
            <p>></p>
          </div>
          <div class="smart-info info-hover" @click="navigateToAllProducts">
            <h3>
              É possível utilizar assistentes de voz e outros serviços como Google Assistant ou
              Alexa?
            </h3>
            <p>></p>
          </div>
          <div class="smart-info info-hover" @click="navigateToAllProducts">
            <h3>Quantas pessoas podem acessar a Smart Central?</h3>
            <p>></p>
          </div>
          <div class="smart-info info-hover" @click="navigateToAllProducts">
            <h3>O que é preciso para acessar minha Smart Central de forma remota?</h3>
            <p>></p>
          </div>
          <div class="smart-info info-hover" @click="navigateToAllProducts">
            <h3>Quais tipos de equipamentos são compatíveis com a Smart Central?</h3>
            <p>></p>
          </div>
          <div class="smart-info info-hover" @click="navigateToService1">
            <h3>Quais são as tecnologias utilizadas pela Smart Central?</h3>
            <p>></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
</template>

<style scoped>
.card {
  background-color: var(--color-shadow-background);
  border-radius: 15px;
}
.title {
  background-color: var(--color-shadow-border);
  width: 250px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 15px;
}

.smart-info-content {
  display: flex;
}

.info-image {
  width: 250px;
  margin-right: 15px;
}

.info-hover:hover {
  cursor: pointer;
  color: aliceblue;
  background-color: var(--color-border);
}

.logo-container {
  padding-top: 20px;
  padding-bottom: 120px;
  position: relative;
  z-index: 3;
  height: 200px;
}

.bg-logo-container {
  height: 0;
  position: relative;
  z-index: 0;
}

.smart-info {
  background-color: var(--color-shadow-background);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-block: 10px;
}

.info-button {
  background-color: var(--color-border);
  color: aliceblue;
  bottom: 5px;
  padding-inline: 8px;
  padding-block: 8px;
  border-radius: 50px;
  border-color: var(--color-text);
  font-size: 1rem;
  height: 40px;
  min-width: 100px;
}

.core-btns {
  padding-top: 15px;
  margin: auto;
  text-align: center;
}

.core-button {
  color: aliceblue;
  background-color: var(--color-border);
  border-color: var(--color-text);
  border-radius: 10px;
  font-size: 1rem;
  height: 40px;
  width: 120px;
  margin-inline: 5px;
  /*animation*/
  animation: rgbShadow 8s infinite alternate;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .smart-info-content {
    display: block;
  }
  .info-image {
    width: 100%;
    margin-right: 0px;
  }
}
</style>
