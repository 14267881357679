<template>
  <Swiper
    class="header-carousel"
    :autoplay="autoplay"
    :pagination="pagination"
    :modules="modules"
    :navigation="true"
    :loop="true"
  >
    <SwiperSlide
      v-for="(header, index) in headers"
      :key="index"
      :style="{ backgroundImage: `url(${header.image})` }"
    >
      <div class="header-splash border">
        <img class="haeder-image" alt="robo" :src="header.service_image" />
        <button class="header-button">saiba mais</button>
      </div>
      <div class="header-content border">
        <p class="header-title">{{ header.title }}</p>
        <p class="header-subtitle">{{ header.subtitle }}</p>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/swiper-bundle.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import HeaderKioskImage from '@/assets/images/headers/header_smarthome.jpg'
import HeaderLightsImage from '@/assets/images/headers/header_lights.avif'
import HeaderSecurityImage from '@/assets/images/headers/header_security.png'
import HeaderOnOffImage from '@/assets/images/headers/header_on_off.webp'
import ServiceKioskImage from '@/assets/images/banner-services/smart_kiosk.webp'
import ServiceLightsImage from '@/assets/images/banner-services/smart_lights.jpeg'
import ServiceSecurityImage from '@/assets/images/banner-services/smart_security.jpg'
import ServiceOnOffImage from '@/assets/images/banner-services/smart_on_off.png'

export default defineComponent({
  name: 'HeaderCarousel',
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const headers = [
      {
        image: HeaderKioskImage,
        title: 'Smart Central',
        subtitle:
          'Uma forma moderna de controlar todos os dispositivos inteligentes de sua residência ou estabelecimento de com uma abordagem escalável e compatível com outras tecnologias do mercado como Google Assistant, Siri ou Alexa',
        service_image: ServiceKioskImage
      },
      {
        image: HeaderLightsImage,
        title: 'Smart Lights',
        subtitle:
          'Crie automações, rotinas, gatilhos para gerenciar todo sistema de iluminação através da Smart Central mesmo que parte de sua iluminação não seja inteligente mantendo o funcionamento dos interruptores.',
        service_image: ServiceLightsImage
      },
      {
        image: HeaderOnOffImage,
        title: 'Smart On/Off',
        subtitle:
          'Transforme qualquer dispositivo ligado a energia eletrica através de tomadas em um dispositivos inteligente que pode ser controlado pela sua Smart Central para ligar/desligar',
        service_image: ServiceOnOffImage
      },
      {
        image: HeaderSecurityImage,
        title: 'Smart Security',
        subtitle:
          'Visualize todas as suas câmeras, status de sensores, detecção de movimento e crie rotinas, gatilhos e automações tudo em um único lugar através da sua Smart Central',
        service_image: ServiceSecurityImage
      }
    ]

    console.log('Header.vue Mock: ', headers)
    return {
      headers,
      pagination: {
        clickable: true
      },
      autoplay: {
        delay: 8000,
        disableOnInteraction: false
      },
      modules: [Autoplay, Pagination, Navigation]
    }
  }
})
</script>

<style scoped>
.header-carousel {
  width: 100%;
  height: 60vh;
}

.haeder-image {
  height: 100%;
  max-width: 100%;
  padding: 5px;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header-splash {
  background-color: var(--color-shadow-background);
  border: none;
  text-align: center;
  padding: 0px;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 60%;
  top: 5px;
}

.header-content {
  color: var(--color-text);
  background-color: var(--color-shadow-background);
  text-align: left;
  padding-block: 5px;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 38%;
  bottom: 5px;
  padding-inline: 10px;
}

.header-button {
  background-color: var(--color-border);
  color: aliceblue;
  bottom: 5px;
  padding-inline: 8px;
  padding-block: 8px;
  border-radius: 50px;
  border-color: var(--color-text);
  position: absolute;
  right: 0;
  font-size: 1rem;
}

.swiper-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.header-title {
  background-color: var(--color-shadow-border);
  font-size: 1.7rem;
  margin: 0;
}

.header-subtitle {
  font-size: 1.2rem;
  padding-inline: 5px;
}

@media (max-width: 768px) {
  .header-title {
    font-size: 1.2rem;
  }

  .header-subtitle {
    font-size: 1rem;
  }

  .header-splash {
    width: 100%;
  }

  .header-content {
    width: 100%;
  }
}
</style>
