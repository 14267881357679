<template>
  <div class="chatbot-container" :class="{ minimized }">
    <div class="chat-header" @click="toggleChat">
      <span>{{ minimized ? 'Chatbot' : 'Fale Conosco' }}</span>
    </div>
    <div v-if="!minimized" class="chat-content">
      <div class="messages">
        <div v-for="message in messages" :key="message.id" :class="['message', message.type]">
          <p>{{ message.text }}</p>
        </div>
      </div>
      <div class="chat-input">
        <input
          v-model="userMessage"
          @keyup.enter="sendMessage"
          placeholder="Digite sua mensagem..."
        />
        <button @click="sendMessage">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'ChatBot',
  setup() {
    const minimized = ref(true)
    const messages = ref([{ id: 1, text: 'Olá, como posso ajudar?', type: 'bot' }])
    const userMessage = ref('')

    // URL do webhook do Dialogflow
    const dialogflowWebhookUrl = 'https://dialogflow.googleapis.com/v2/...'

    const toggleChat = () => {
      minimized.value = !minimized.value
    }

    const sendMessage = async () => {
      if (userMessage.value.trim() === '') return

      // Adiciona a mensagem do usuário na tela
      messages.value.push({ id: Date.now(), text: userMessage.value, type: 'user' })

      try {
        // Envia a mensagem para o Dialogflow
        const response = await axios.post(dialogflowWebhookUrl, {
          queryInput: {
            text: {
              text: userMessage.value,
              languageCode: 'pt-BR'
            }
          }
        })

        const botMessage = response.data.fulfillmentText
        // Adiciona a resposta do bot
        messages.value.push({ id: Date.now(), text: botMessage, type: 'bot' })
      } catch (error) {
        messages.value.push({ id: Date.now(), text: 'Desculpe, algo deu errado.', type: 'bot' })
      } finally {
        userMessage.value = ''
      }
    }

    return {
      minimized,
      messages,
      userMessage,
      toggleChat,
      sendMessage
    }
  }
})
</script>

<style scoped>
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: aliceblue;
  border-radius: 10px;
  overflow: hidden;
  z-index: 999;
  /* Adiciona uma animação de cor ao box-shadow */
  animation: rgbShadow 8s infinite alternate;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
}

.chat-header {
  background-color: var(--color-border);
  color: aliceblue;
  padding: 10px;
  cursor: pointer;
}

.chat-content {
  color: var(--color-text);
  background-color: var(--color-background);
  max-height: 400px;
  display: flex;
  flex-direction: column;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin-bottom: 10px;
}

.message.user {
  text-align: right;
}

.message.bot {
  text-align: left;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chat-input button {
  background-color: var(--color-border);
  color: aliceblue;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .chatbot-container {
    bottom: 20px;
    right: 10px;
    width: 300px;
  }
}
</style>
