<template>
  <div class="container">
    <div class="search-container">
      <LogoAnimation />
      <br />
      <div class="search-input">
        <SearchBar />
        <button class="btn-advanced-search" @click="navigateToAdvancedSearch">
          Busca Avançada
        </button>
      </div>
    </div>
    <h2>Best Products</h2>
    <ProductCarousel :cards="products">
      <template #default="{ card }">
        <ProductCard :product="card" />
      </template>
    </ProductCarousel>
    <br />
    <h2>Product Categories</h2>
    <div>
      <CategoryCarousel :categories="productCategories" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import ProductCard from '../components/ProductCard.vue'
import ProductCarousel from '../components/ProductCarousel.vue'
import CategoryCarousel from '../components/CategoryCarousel.vue'
import {
  getAllProducts,
  getAllProductCategories
} from '../services/lugand-api-services/productService'
import type { Product, Category } from '@/types/product'
import LogoAnimation from '@/components/animations/LogoAnimation.vue'
import SearchBar from '@/components/SearchBar.vue'

export default defineComponent({
  name: 'ProductDetailsView',
  components: {
    ProductCard,
    ProductCarousel,
    CategoryCarousel,
    LogoAnimation,
    SearchBar
  },
  setup() {
    const router = useRouter()

    const products = ref<Product[]>([])
    const productCategories = ref<Category[]>([])

    async function fetchData() {
      products.value = await getAllProducts()
      productCategories.value = await getAllProductCategories()
    }
    fetchData()

    const navigateToAdvancedSearch = () => {
      router.push({ name: 'search-results' })
    }

    return {
      products,
      productCategories,
      navigateToAdvancedSearch
    }
  }
})
</script>

<style scoped>
.btn-advanced-search {
  color: aliceblue;
  background-color: var(--color-border);
  border-color: var(--color-text);
  border-radius: 10px;
  font-size: 1rem;
  height: 30px;
  margin-top: 10px;
}

.search-container {
  padding-inline: 8px;
  padding-top: 20px;
  padding-bottom: 120px;
  height: 240px;
}

.search-input {
  width: 360px;
  max-width: 100%;
  margin: auto;
}

@media (max-width: 768px) {
}
</style>
