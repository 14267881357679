<template>
  <div class="checkout-container">
    <!-- Título e resumo do checkout -->
    <div class="fancy-title">
      <h1>Checkout</h1>
      <hr />
    </div>
    <br />

    <div v-if="items.length > 0" class="container">
      <!-- Itens no checkout -->
      <div class="fancy-title">
        <h2>Items</h2>
        <hr />
      </div>
      <div v-for="(item, index) in items" :key="index" class="checkout-item">
        <img :src="item.product.images[0].image" alt="Product" class="product-image" />
        <div class="checkout-details">
          <p>{{ item.product.name }}</p>
          <p>R$ {{ item.product.price.valor_original }} - uni</p>
        </div>
        <div class="checkout-details">
          <p>
            Quantity: <br />
            {{ item.quantity }}
          </p>
        </div>
        <div class="checkout-details">
          <p>
            Total: <br />
            R$ {{ calculateItemTotal(item) }}
          </p>
        </div>
      </div>

      <!-- Seção de Endereço de Entrega -->
      <div class="fancy-title">
        <h2>Delivery Address</h2>
        <hr />
      </div>
      <div v-if="addresses && addresses.length > 0" class="address-selection">
        <div v-for="(address, index) in addresses" :key="index" class="address-item">
          <input type="radio" :id="'address-' + index" :value="address" v-model="selectedAddress" />
          <label :for="'address-' + index"
            >{{ address.street }}, {{ address.number }} - {{ address.city }},
            {{ address.state }},<br />
            CEP:{{ address.cep }}</label
          >
        </div>
      </div>
      <p v-else>No address available. Please add an address.</p>

      <!-- Botão para adicionar novo endereço -->
      <button @click="openAddressModal">+ New Address</button>

      <!-- Modal para adicionar novo endereço -->
      <div v-if="showModal" class="modal-overlay">
        <div class="modal-content">
          <h2>Add New Address</h2>
          <AddressForm @save="addNewAddress" @cancel="closeModal" />
        </div>
      </div>

      <!-- Seção de Cálculo de Frete -->
      <div v-if="selectedAddress" class="fancy-title">
        <h2>Freight Calculation</h2>
        <hr />
        <CalculoFrete :cepDestino="'83320320'" />
      </div>

      <!-- Seção de Pagamento -->
      <div class="fancy-title">
        <h2>Payment Method</h2>
        <hr />
      </div>

      <!-- Resumo do Total -->
      <div class="fancy-title">
        <h2>Total</h2>
        <hr />
      </div>
      <h1>R$ {{ calculateTotal() }}</h1>

      <button @click="proceedToPayment">Proceed to Payment</button>
    </div>

    <div v-else>
      <p>No items to checkout.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useCheckoutStore } from '@/stores/lugand-api/checkoutStore'
import { useUserStore } from '@/stores/lugand-api/userStore'
import AddressForm from '@/components/AddressForm.vue'
import CalculoFrete from '@/components/CalculoFrete.vue'

export default defineComponent({
  name: 'CheckoutView',
  components: {
    AddressForm,
    CalculoFrete
  },
  setup() {
    const checkoutStore = useCheckoutStore()
    const userStore = useUserStore()
    const items = checkoutStore.items

    const selectedAddress = ref(null) // Armazena o endereço selecionado
    const showModal = ref(false) // Controla a visibilidade da modal

    // Computa os endereços do usuário a partir do userStore
    const addresses = computed(() => userStore.user?.addresses || [])

    const calculateItemTotal = (item: any) => {
      const price = parseFloat(item.product.price.valor_original)
      return (price * item.quantity).toFixed(2)
    }

    const calculateTotal = () => {
      return items
        .reduce((total, item) => {
          const price = parseFloat(item.product.price.valor_original)
          return total + price * item.quantity
        }, 0)
        .toFixed(2)
    }

    const proceedToPayment = () => {
      if (!selectedAddress.value) {
        alert('Please select a delivery address.')
        return
      }
      console.log(
        'Proceeding with payment for items:',
        items,
        'and address:',
        selectedAddress.value
      )
      // Aqui você pode redirecionar para o pagamento
    }

    // Funções para lidar com a modal
    const openAddressModal = () => {
      showModal.value = true
    }

    const closeModal = () => {
      showModal.value = false
    }

    // Adiciona o novo endereço e atualiza o store
    const addNewAddress = (newAddress: any) => {
      if (userStore.user) {
        const updatedAddresses = [...(userStore.user.addresses || []), newAddress]
        userStore.setUser({ addresses: updatedAddresses })
        showModal.value = false // Fecha a modal
      }
    }

    return {
      items,
      addresses,
      selectedAddress,
      calculateItemTotal,
      calculateTotal,
      proceedToPayment,
      showModal,
      openAddressModal,
      closeModal,
      addNewAddress
    }
  }
})
</script>

<style scoped>
.container {
  padding: 5px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
}

.checkout-container {
  padding-bottom: 20px;
}

/* Container principal dos itens de checkout */
.checkout-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid var(--color-shadow-border);
}

/* Estilo da imagem do produto */
.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 5px;
}

/* Responsabilidade para elementos de detalhe */
.checkout-details {
  flex: 1;
  padding: 0 10px;
}

button {
  margin-top: 10px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.address-selection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.address-item {
  display: flex;
  align-items: center;
}

.address-item input[type='radio'] {
  margin-right: 10px;
}

/* Estilos para telas menores */
@media (max-width: 420px) {
  .checkout-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .checkout-details {
    padding: 10px 0;
  }
}
</style>
