<template>
  <div class="service-page-container">
    <!-- Menu lateral -->
    <div class="side-menu" :class="{ notexpanded: isMenuNotExpanded }">
      <button @click="toggleMenu" class="menu-toggle">
        {{ isMenuNotExpanded ? '>>' : '<<' }}
      </button>
      <ul class="menu-items">
        <li v-for="(section, index) in sections" :key="index">
          <a @click="scrollToSection(section.id)">{{ section.title }}</a>
          <ul v-if="!isMenuNotExpanded" class="sub-menu-items">
            <li v-for="(contentItem, contentIndex) in section.content" :key="contentIndex">
              <a @click="scrollToSubSection(section.id, contentIndex)">
                - {{ contentItem.title || 'Subsection' }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- Conteúdo principal -->
    <div class="content">
      <div class="logo-container">
        <LogoAnimation />
      </div>

      <div
        v-for="(section, index) in sections"
        :key="index"
        :id="section.id"
        class="service-section"
      >
        <h1>{{ section.title }}</h1>
        <img :src="section.image" alt="Service" class="section-image" />

        <!-- Renderiza múltiplos conteúdos por sessão como sub-seções -->
        <div
          v-for="(contentItem, contentIndex) in section.content"
          :key="contentIndex"
          :id="`${section.id}-${contentIndex}`"
          class="content-item"
        >
          <h3>{{ contentItem.title || 'Subsection' }}</h3>
          <p>{{ contentItem.description }}</p>
        </div>

        <p class="section-description">{{ section.description }}</p>

        <button class="section-button" @click="navigateToSection(section.id)">
          {{ section.buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LogoAnimation from '../components/animations/LogoAnimation.vue'
import ImageDefault from '@/assets/images/services/default.jpg'

export default defineComponent({
  name: 'ServicePage',
  components: { LogoAnimation },
  setup() {
    const isMenuNotExpanded = ref(false) // Menu fechado por padrão
    const route = useRoute()
    const router = useRouter()

    const sections = ref([
      {
        id: 'section-1',
        title: 'Smart Central',
        content: [
          { title: 'Overview', description: 'An overview of Smart Central' },
          { title: 'Features', description: 'Some key features of Smart Central' },
          { title: 'Integration', description: 'How Smart Central integrates with other systems' }
        ],
        image: ImageDefault,
        buttonText: 'Learn More',
        description: 'A more detailed description about Service 1.'
      },
      {
        id: 'section-2',
        title: 'Consultoria',
        content: [
          { title: 'What we offer', description: 'Details about our consulting services' },
          { title: 'Our Process', description: 'Steps we take to deliver our consulting services' }
        ],
        image: ImageDefault,
        buttonText: 'Explore Now',
        description: 'A more detailed description about Service 2.'
      }
    ])

    const navigateToSection = (sectionId: string) => {
      if (sectionId === 'section-1') {
        router.push({ name: 'specific-service-page', params: { id: 1 } })
      } else {
        console.log('Button clicked for section:', sectionId)
      }
    }

    const toggleMenu = () => {
      isMenuNotExpanded.value = !isMenuNotExpanded.value
    }

    const scrollToSection = (sectionId: string) => {
      const sectionElement = document.getElementById(sectionId)
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const scrollToSubSection = (sectionId: string, contentIndex: number) => {
      const subSectionId = `${sectionId}-${contentIndex}`
      const subSectionElement = document.getElementById(subSectionId)
      if (subSectionElement) {
        subSectionElement.scrollIntoView({ behavior: 'smooth' })
      }
    }

    // Verifica se há um hash na URL e rola até a sessão correspondente
    const scrollToHashSection = () => {
      const hash = route.hash.replace('#', '')
      if (hash) {
        scrollToSection(hash)
      }
    }

    onMounted(() => {
      scrollToHashSection()
    })

    watch(route, () => {
      scrollToHashSection()
    })

    return {
      isMenuNotExpanded,
      sections,
      toggleMenu,
      scrollToSection,
      scrollToSubSection,
      navigateToSection
    }
  }
})
</script>

<style scoped>
.service-page-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.side-menu {
  background-color: var(--color-border);
  transition: width 0.3s ease;
  position: fixed;
  height: 100%;
  width: 250px;
}
.side-menu a {
  transition: width 0.3s ease;
  height: 40px;
  width: 250px;
}

.side-menu li:hover {
  background-color: var(--color-shadow-text);
  cursor: pointer;
}

.side-menu.notexpanded {
  width: 0px;
  padding: 0px;
}
.side-menu.notexpanded a {
  display: none;
}

.menu-toggle {
  position: absolute;
  right: -80px;
  z-index: 2;
  background-color: var(--color-border);
  color: white;
  border: 1px solid;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  width: 80px;
  height: 40px;
}

.menu-items {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-items li {
  margin-bottom: 10px;
}

.sub-menu-items {
  list-style-type: none;
  padding-left: 5px;
  margin-top: 5px;
}

.menu-items a {
  text-decoration: none;
  cursor: pointer;
  color: aliceblue;
  padding-left: 10px;
}

.content {
  padding: 20px;
  margin: auto;
  width: 60%;
}

.service-section {
  padding-top: 40px;
  margin-bottom: 40px;
}

.section-description {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.section-image {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}

.section-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

.section-button:hover {
  background-color: #0056b3;
}

.content-item {
  padding-top: 40px;
  margin-bottom: 20px;
}

.content-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.content-item p {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .content {
    width: 100%;
  }
}
</style>
