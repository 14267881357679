<template>
  <div class="cart-list">
    <button class="toggle-btn header" @click="toggleCartList">
      <h2>Cart List</h2>
      {{ isCollapsed ? 'Show Cart List' : 'Hide Cart List' }}
    </button>

    <transition name="fade">
      <div v-if="!isCollapsed" class="scroller">
        <!-- main.css -->
        <div v-for="cart in carts" :key="cart.id" class="cart" @click="viewCartDetails(cart.id)">
          <a>
            <h2>{{ cart.name }}</h2>
            <ul>
              <li>Items Quantity: {{ calculateTotalQuantity(cart) }}</li>
              <li>Total Value: {{ calculateTotalPrice(cart) }}</li>
            </ul>
          </a>
          <button @click.stop="confirmRemoveCart(cart.id)">Delete Cart</button>
        </div>
      </div>
    </transition>
  </div>

  <!-- Modal de confirmação usando GenericModal -->
  <GenericModal :isVisible="showConfirmModal" @close="closeModal">
    <h2>Confirm Cart Deletion</h2>
    <p>Are you sure you want to delete this cart?</p>
    <button @click="deleteCart">Confirm</button>
    <button @click="closeModal">Cancel</button>
  </GenericModal>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue'
import { useCartStore } from '@/stores/lugand-api/cartStore'
import type { FullCart } from '@/types/cart'
import { useRouter } from 'vue-router'
import GenericModal from '@/components/GenericModal.vue'

export default defineComponent({
  name: 'CartList',
  components: { GenericModal },
  setup() {
    const cartStore = useCartStore()
    const router = useRouter()
    const carts = computed(() => cartStore.carts)
    const showConfirmModal = ref(false)
    const cartIdToDelete = ref<string | null>(null)
    const isCollapsed = ref(false) // Estado para controlar o colapso da lista

    const toggleCartList = () => {
      isCollapsed.value = !isCollapsed.value
    }

    const viewCartDetails = (cartId: string) => {
      router.push({ name: 'cart-details', params: { id: String(cartId) } })
      // isCollapsed.value = true; // Colapsa o cart list automaticamente após clicar no item
    }

    const confirmRemoveCart = (cartId: string) => {
      cartIdToDelete.value = cartId
      showConfirmModal.value = true
    }

    const deleteCart = async () => {
      if (cartIdToDelete.value) {
        await cartStore.removeCart(cartIdToDelete.value)
        showConfirmModal.value = false
        cartIdToDelete.value = null
      }
    }

    const closeModal = () => {
      showConfirmModal.value = false
      cartIdToDelete.value = null
    }

    const calculateTotalQuantity = (cart: FullCart) => {
      return cart.items.reduce((total, item) => total + item.quantity, 0)
    }

    const calculateTotalPrice = (cart: FullCart) => {
      return cart.items
        .reduce(
          (total, item) => total + Number(item.product.price.valor_a_vista) * item.quantity,
          0
        )
        .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    onMounted(() => {
      cartStore.fetchCarts()
    })

    return {
      carts,
      isCollapsed,
      toggleCartList,
      viewCartDetails,
      confirmRemoveCart,
      deleteCart,
      closeModal,
      showConfirmModal,
      cartIdToDelete,
      calculateTotalQuantity,
      calculateTotalPrice
    }
  }
})
</script>

<style scoped>
.cart-list {
  background-color: var(--color-shadow-border);
}

ul {
  list-style-type: none;
  padding: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
}

.toggle-btn {
  padding: 5px 10px;
  background-color: var(--color-border);
  color: aliceblue;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.toggle-btn:hover {
  border: 1px solid var(--color-border);
}

.scroller {
  max-height: 80vh;
  overflow-y: auto;
}

.cart {
  background-color: var(--color-background);
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
}

.cart:hover {
  background-color: var(--color-border);
  color: var(--color-background);
}

@media (max-width: 768px) {
  .cart {
    width: 100%;
  }

  .scroller {
    max-height: 50vh;
    /* Ajusta a altura da lista em telas menores */
  }

  .header {
    max-width: 100%;
  }

  .toggle-btn {
    width: 100%;
  }
}
</style>
